<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Detail Termin" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. IPL</label>
            <vs-input class="w-full" :value="data.no_ipl" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Pengelola</label>
            <vs-input class="w-full" :value="data.nama_pengelola" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No Unit</label>
            <vs-input class="w-full" :value="`${data.kode_proyek_unit} | ${data.no_unit}`" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Due</label>
            <vs-input class="w-full" :value="data.tgl_due" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Status</label>
            <vs-input class="w-full" :value="data.status" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Nilai</label>
            <vs-input class="w-full" :value="grandTotal | idr" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan" readonly/>
          </div>
        </div>

        <vs-divider class="mt-base">Item Pekerjaan IPL</vs-divider>

        <vs-table :data="data.pekerjaans" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap text-center">Nama Item IPL</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Tarif</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Tgl. Mulai</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Tgl. Akhir</vs-th>
            <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td class="whitespace-no-wrap">{{ item.nama_item_ipl }}</vs-td>
              <vs-td class="whitespace-no-wrap text-right">{{ item.tarif | idr }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_awal | formatDate('YYYY-MM-DD') }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.tgl_akhir | formatDate('YYYY-MM-DD') }}</vs-td>
              <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
            </vs-tr>
            <!--footer-->
            <vs-tr class="text-sm" v-if="data.length > 0">
              <vs-td class="font-bold text-left"></vs-td>
              <vs-td class="font-bold text-right">{{ grandTotal | idr }}</vs-td>
              <vs-td class="font-bold text-right" colspan="3"></vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import IplRepository from '@/repositories/estate/ipl-repository'
import _ from 'lodash'

export default {
  name: 'TerminShow',
  props: ['isActive', 'idIpl'],
  data () {
    return {
      isLoadingInitData: false,
      data: {
        pekerjaans: []
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.pekerjaans, item => parseFloat(item.tarif || '0'))
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true
      IplRepository.showTermin(this.idIpl)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      if (!isActive) this.resetData()
    }
  }
}
</script>
